<template>
    <v-simple-table>
        <template v-slot:default>
            <tbody>
                <tr v-for="(value, name) in tags" :key="name + value">
                    <td>{{ name }}</td>
                    <td>
                        <span v-if="isLink(value)">
                            <a :href="value">{{value}}</a>
                        </span>
                        <span v-else-if="isPhone(value)">
                            <a :href="`tel:${value}`">{{ value }}</a>
                        </span>
                        <span v-else>
                            {{ value }}
                        </span>
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import { isPossiblePhoneNumber } from 'libphonenumber-js'
export default {
    "name": "TagTable",
    "props": {
        tags: Object,
    },
    methods: {
        isLink(value) {
            if (value == "") {
                return false
            }
            if (value.toString().startsWith("http")) {
                return true
            }
            return false
        },
        isPhone(value) {
            return isPossiblePhoneNumber(value)
        }
    }
}
</script>