<template>
  <v-card elevation="2">
    <v-card-title>📌 Etappen</v-card-title>
    <v-timeline align-top dense>
        <v-timeline-item small v-for="stay in overnightStays" :key="stay.date">
            <v-row class="pt-1">
                <v-col>
                    <strong v-if="stay.city != ''">
                        {{ emojiFlag(stay.country_code)}}
                        <a :href="`https://www.openstreetmap.org/?mlat=${stay.location.latitude}&mlon=${stay.location.longitude}`" target="_blank">
                            {{ stay.city }}
                        </a>
                    </strong>
                    <strong v-else>
                        "Unbekannt"
                    </strong>
                    <div class="text-caption mb-2">
                        {{ toLocal(stay.date) }}
                    </div>
                </v-col>
            </v-row>
        </v-timeline-item>
        <v-timeline-item small>
            <v-row class="pt-1">
                <v-col>
                    <strong>{{ emojiFlag("DE")}} Berlin</strong>
                    <div class="text-caption mb-2">
                        24.3.2022
                    </div>
                </v-col>
            </v-row>
        </v-timeline-item>
    </v-timeline>
  </v-card>
</template>

<script>
import EmojiFlag from "@/mixins/EmojiFlag.vue";
import dayjs from 'dayjs'

export default {
  name: 'OvernightStays',
  mixins: [EmojiFlag],
  props: {
    overnightStays: Array,
  },
  methods: {
      toLocal: (v) => {
          return dayjs(v).tz("Europe/Berlin").format('D.M.YYYY')
      }
  }
}
</script>
