<template>
  <v-card elevation="2">
    <v-card-title>🏕️ Campingplätze in der Nähe</v-card-title>
    <v-card-subtitle>In einem 15km Radius</v-card-subtitle>
    <v-divider />
    <v-expansion-panels accordion v-if="campSites.length > 0">
        <v-expansion-panel v-for="campSite in campSites.slice(0, 5)" :key="campSite.tags['name']">
            <v-expansion-panel-header>
                {{ campSite.tags["name"] || "Kein Name gefunden"}}
                <v-spacer/>
                <span class="text-right">{{ campSite.beeline_distance_km.toFixed(2) }} km</span>
            </v-expansion-panel-header>
            <v-divider />
            <v-expansion-panel-content>
              <TagTable :tags="campSite.tags" />
              <v-card-actions>
                <v-spacer />
                <OpenStreetMapLink :lat="campSite.latitude" :lon="campSite.longitude" />
                <v-spacer />
              </v-card-actions>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
    <v-card-text v-else>
      Derzeit befinden sich keine Campingplätze in der Nähe.
    </v-card-text>
  </v-card>
</template>

<script>
import OpenStreetMapLink from "./OpenStreetMapLink.vue"
import TagTable from "./TagTable.vue";
  export default {
    name: "CampSites",
    props: {
        campSites: Array,
    },
    components: { OpenStreetMapLink, TagTable }
}
</script>
