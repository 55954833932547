<template>
  <v-card elevation="2">
    <v-card-title>🚰 Trinkwasser in der Nähe</v-card-title>
    <v-card-subtitle>In einem 15km Radius</v-card-subtitle>
    <v-divider />
    <v-expansion-panels accordion v-if="pois.length > 0">
      <v-expansion-panel v-for="poi in pois.slice(0, 5)" :key="poi.latitude">
        <v-expansion-panel-header>
          <span v-if="poi.tags.hasOwnProperty('name')">{{ poi.tags['name'] }}</span>
          <span v-else>Trinkwasser</span>
          <v-spacer />
          <span class="text-right">{{ poi.beeline_distance_km.toFixed(2) }} km</span>
        </v-expansion-panel-header>
        <v-divider />
        <v-expansion-panel-content>
          <TagTable :tags="poi.tags" />
          <v-card-actions>
            <v-spacer />
            <OpenStreetMapLink :lat="poi.latitude" :lon="poi.longitude" />
            <v-spacer />
          </v-card-actions>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card-text v-else>Derzeit befinden sich kein Trinkwasser in der Nähe.</v-card-text>
  </v-card>
</template>

<script>
import OpenStreetMapLink from "./OpenStreetMapLink"
import TagTable from "./TagTable"
export default {
    name: "DrinkingWaterList",
    props: {
        pois: Array,
    },
    components: { OpenStreetMapLink, TagTable }
}
</script>