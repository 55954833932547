<template>
    <span>{{ shelterName }}<span v-if="drinkingWaterAvailable"> 🚰</span></span>
</template>

<script>
export default {
    name: 'ShelterName',
    props: {
        shelterTags: Object,
    },
    computed: {
        shelterName() {
            var type = this.shelterTags["shelter_type"]
                ? this.shelterTags["shelter_type"]
                : this.shelterTags["tourism"]
                    ? this.shelterTags["tourism"]
                    : ""
            return this.translateShelterType(type)
        },
        drinkingWaterAvailable() {
            return this.shelterTags["drinking_water"] && this.shelterTags["drinking_water"] == "yes"
        }
    },
    methods: {
        translateShelterType(type) {
            switch (type) {
                case "gazebo":
                    return "Pavillon"
                case "basic_hut":
                    return "einfache Hütte"
                case "lean_to":
                    return "offene Schutzhütte"
                case "picnic_shelter":
                    return "Picknick Hütte"
                case "picnic_site":
                    return "Picknickplatz"
                case "public_transport":
                    return "🚏 Wartehäuschen"
                case "weather_shelter":
                    return "Wetterschutzhütte"
                default:
                    return "Unterstand"
            }
        },
    }
}
</script>