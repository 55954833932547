<template>
  <v-card elevation="2">
    <v-card-title>🏆 Zahlen, Digga</v-card-title>
    <v-divider />
    <v-simple-table>
      <template v-slot:default>
        <tbody>
          <tr>
            <td>Tage unterwegs</td>
            <td>{{ total_days }}</td>
          </tr>
          <tr>
            <td>Distanz gesamt</td>
            <td>{{ distance_travelled_km.toFixed(2) }} km</td>
          </tr>
          <tr>
            <td>Größte Distanz <small>{{ greatest_distance_date }}</small></td>
            <td>{{ greatest_distance_km.distance_km.toFixed(2) }} km</td>
          </tr>
          <tr>
            <td>Distanz heute</td>
            <td>{{ distance_today.toFixed(2) }} km</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import dayjs from 'dayjs'

var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: 'AchievementList',
  props: {
    distance_travelled_km: Number,
    distance_today_km: Object,
    greatest_distance_km: Object,
  },
  computed: {
    total_days() {
      return dayjs().diff('2022-03-24', 'day')
    },
    greatest_distance_date() {
      return dayjs(this.greatest_distance_km.date).tz("Europe/Berlin").format('D.M.YYYY')
    },
    distance_today() {
      var distanceDate = dayjs(this.distance_today_km.date).startOf('date')
      var today = dayjs().startOf('date')

      if (distanceDate.isBefore(today)) {
        return 0.0
      }

      return this.distance_today_km.distance_km
    }
  }
}
</script>
