<template>
    <v-btn
        text
        color="primary"
        :href="link"
        target="_blank"
    >Auf Karte anzeigen</v-btn>
</template>

<script>
export default {
    "name": "OpenStreetMapLink",
    "props": {
        "lat": Number,
        "lon": Number,
    },
    computed: {
        link() {
            return `https://www.openstreetmap.org/?mlat=${this.lat}&mlon=${this.lon}`
        }
    }
}
</script>