<template>
  <v-container max-width="1185">
    <v-app>
        <v-app-bar app>
            <v-spacer />
            <v-app-bar-title>🚴 Wo ist Buddi?</v-app-bar-title>
            <v-spacer />
        </v-app-bar>

        <v-main >
            <v-row>
              <v-col sm="6" md="6">
                <v-row>
                  <v-col>
                    <CurrentLocation v-bind:locationInfo="data.last_location_info" />
                  </v-col>
                </v-row>
              </v-col>
              <v-col sm="6" md="6">
                <v-row>
                  <v-col>
                    <CampSites v-bind:campSites="data.nearby_camp_sites" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ShelterList v-bind:pois="data.nearby_shelters" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <DrinkingWaterList v-bind:pois="data.nearby_drinking_water" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6" md="6">
                <AchievementList v-bind:distance_travelled_km="data.distance_travelled_km"
                                 v-bind:distance_today_km="data.distance_today_km"
                                 v-bind:greatest_distance_km="data.greatest_distance_km"
                                 v-bind:days_in_countries="data.days_in_countries"
                />
              </v-col>
              <v-col sm="6" md="6">
                <CountryList v-bind:countries="data.countries" />
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6" md="6">
                <OvernightStays v-bind:overnightStays="data.overnight_stays" />
              </v-col>
            </v-row>
        </v-main>
    </v-app>
  </v-container>
</template>

<script>
import CurrentLocation from './components/CurrentLocation'
import CampSites from './components/CampSites'
import AchievementList from './components/AchievementList'
import CountryList from './components/CountryList'
import OvernightStays from './components/OvernightStays'
import ShelterList from './components/ShelterList'
import DrinkingWaterList from './components/DrinkingWaterList.vue'

export default {
  name: 'App',
  
  components: {
    CurrentLocation,
    CampSites,
    AchievementList,
    CountryList,
    OvernightStays,
    ShelterList,
    DrinkingWaterList,
},

  data: () => ({
    data: {
      last_location_info: {
        location: {
          address: "",
          country_name: "",
          country_code: "DE",
          locality: "",
          timestamp: "2022-03-24T00:00:00Z",
          latitude: 0.0,
          longitude: 0.0,
        },
        weather: {
          unit: "",
          temp: 0.0,
          temp_feels_like: 0.0,
          icon: "",
          wind_speed_ms: 0.0,
          wind_direction_deg: 0.0,
        },
      },
      nearby_camp_sites: [],
      nearby_shelters: [],
      nearby_drinking_water: [],
      distance_travelled_km: 0.0,
      greatest_distance_km: {
        date: "2022-03-24T00:00:00Z",
        distance_km: 0.0
      },
      distance_today_km: {
        date: "",
        distance_km: 0.0,
      },
      countries: [],
      overnight_stays: [],
      current_target: {
        "name": "Lievelinge",
            "cc": "NL",
            "start_latitude": 52.520008,
            "start_longitude": 13.404954,
            "target_latitude": 51.8458135,
            "target_longitude": 5.0398064,
            "distance_total_km": 574.896,
            "distance_left_km": 359.226,
            "meta": {
              "address": "Haarweg 6, 4214 KL Vuren, Netherlands",
              "phone": "+31 183 630 631",
              "website": "https://lievelinge.nl/"
            }
      }
    }
  }),

  mounted: function() {
    var vm = this;
    fetch(this.$config.api.path)
      .then(r => r.json())
      .then(d => {
        vm.data.last_location_info = d.last_location_info
        vm.data.nearby_camp_sites = d.nearby_camp_sites
        vm.data.nearby_shelters = d.nearby_shelters
        vm.data.nearby_drinking_water = d.nearby_drinking_water
        vm.data.greatest_distance_km = d.greatest_distance_km
        vm.data.distance_travelled_km = d.distance_travelled_km
        vm.data.distance_today_km = d.distance_today_km
        vm.data.countries = d.countries
        vm.data.overnight_stays = d.overnight_stays.reverse()
        vm.data.current_target = d.current_target
      })
      .catch(err => {
        console.log(err)
      })
  }
};
</script>
